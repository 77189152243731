<template>
  <div class="feed-back main-wrap">
    <search-form @search="searchList"></search-form>
    <feed-table :list="list" @switch="updateStatus"></feed-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="currentPage"
    ></el-pagination>
  </div>
</template>

<script>
import searchForm from './components/search-form';
import feedTable from './components/feed-table';

import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
      searchData: {}
    };
  },
  components: {
    searchForm,
    feedTable
  },
  methods: {
    init() {
      let initParam = { pageNum: 1 };
      this.getList(initParam);
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 搜索
    searchList(data = {}) {
      this.searchData = data;
      return this.getList({ pageNum: 1, ...data });
    },
    // 更改状态
    updateStatus(row) {
      return service.update({ id: row.id, status: ~~row.status }).then(res => {
        this.$message({ message: '操作成功！', type: 'success', offset: 400, duration: 1000 });
        this.init();
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.feed-back {
}
</style>
