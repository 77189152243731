import API from "@/api";

export default {
  // 获取列表
  getList: params => {
    return API.requestPost({
      url: "feedback/getFeedbackData",
      data: params
    });
  },
  update: params => {
    return API.requestPost({
      url: "feedback/updateFeedbackData",
      data: params
    });
  }
};
