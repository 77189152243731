<template>
  <div class="feed table-wrap">
    <el-table :data="list" style="width: 100%" border>
      <el-table-column prop="tempId" label="序号" align="center" width="60"></el-table-column>
      <el-table-column prop="content" label="内容" align="center" min-width="160"></el-table-column>
      <el-table-column prop="createDate" label="日期" align="center" sortable></el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center" width="120"></el-table-column>
      <el-table-column prop="mobile" label="查看图片" align="center" width="120">
        <template slot-scope="scope">
          <div class="demo-image__preview" v-if="scope.row.imgUrl">
            <el-image 
              style="width: 100px; height: 50px"
              :src="url(scope.row.imgUrl)" 
              :preview-src-list="srcList"
              @click="seeImg(scope.row.imgUrl)">
            </el-image>
          </div>
           <el-tag v-if="!scope.row.imgUrl" type="info">暂无</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="来源" align="center" width="70">
        <template slot-scope="scope">{{scope.row.sourceStatus ? '商户端' : 'APP端'}}</template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="70">
        <template slot-scope="scope">{{scope.row.status ? '已解决' : '未解决'}}</template>
      </el-table-column>
      <el-table-column label="解决状态" align="center" width="170">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#13ce66"
            
            :active-value="1"
            inactive-color="#ff4949"
            :inative-value="0"
            @change="$emit('switch', scope.row)"
            
          ></el-switch>
            <!-- inactive-text="未解决" active-text="已解决" -->
        </template>
      </el-table-column>
    </el-table>
    
  </div>
</template>

<script>
export default {
  props: {
    list: {
      tyep: Array,
      default: () => []
    }
  },
  data(){
    return {
      srcList: [
      ]
    }
  },
  methods:{
    seeImg(data){
      let arr = data.split(',')
      arr.pop()
      this.srcList = arr
    },
    url(data){
      return data.split(',')[0]
    }
  },
};
</script>

<style lang="scss" scoped>
.feed {
  width: 100%;
  .el-switch {
    white-space: nowrap;
  }
}

</style>
