<template>
  <div class="search">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-select v-model="search.status" placeholder="状态">
          <el-option
            v-for="item in statusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="search.sourceStatus" placeholder="来源">
          <el-option
            v-for="item in sourceOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$emit('search', search)">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: { sourceStatus: null, status: null },
      statusOpts: [
        { label: '请选择', value: '' },
        { label: '已解决', value: 1 },
        { label: '未解决', value: 0 }
      ],
      sourceOpts: [
        { label: '请选择', value: '' },
        { label: 'APP端', value: 0 },
        { label: '商户端', value: 1 }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.search {
  text-align: left;
  .feed-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
}
</style>